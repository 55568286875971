
import Banner from '../../home/components/banner.vue';
import Footer from '../../footer/components/footer';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    Banner,
    Footer,
  },
  async created() {
    var category = this.checkCategory();
    await this.doFetchPortfolio(category);
  },
  data() {
    return {
      logo: require('../../../assets/logo.svg'),
      title: '',
      description: '',
      description2: '',
      nextTitle: '',
      nextUrl: '',
      previousTitle: '',
      previousUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      portfolio: 'portfolio/portfolio',
      loading: 'portfolio/loading',
    }),
    isMobile() {
      return screen.width < 500;
    },
  },
  methods: {
    ...mapActions({
      doFetchPortfolio: 'portfolio/doFetchPortfolio',
    }),
    async goToPreviousCategory() {
      this.$router.push({
        path: '/portfolio/' + this.previousUrl,
      });
      var category = this.checkCategory();
      await this.doFetchPortfolio(category);
    },
    async goToNextCategory() {
      this.$router.push({
        path: '/portfolio/' + this.nextUrl,
      });
      var category = this.checkCategory();
      await this.doFetchPortfolio(category);
    },
    checkCategory() {
      var category;
      if (this.$route.params.category == 'case-study') {
        category = 'Case Study';
        this.title = 'Case Study';
        this.description =
          'Now we give you some of our case studies ';
        this.description2 = '';
        this.nextTitle = 'Websites';
        this.nextUrl = 'websites';
        this.previousTitle = 'UI/UX';
        this.previousUrl = 'ui-ux';
      } else if (
        this.$route.params.category == 'websites'
      ) {
        category = 'Website';
        this.title = 'Web Development';
        this.description =
          'The approach we follow involves first getting an understanding of what our clients is looking to achieve and then coming up with options on how to achieve using the latest technology as Vue JS, Angular JS, Node JS, Word Press and Ruby on Rails to attract and engage particular audience needs.';
        this.description2 =
          'Runprof makes the web development process smooth and create value-driven and complex web products to satisfy our client’s vision.';
        this.nextTitle = 'Mobile Applications';
        this.nextUrl = 'mobile';
        this.previousTitle = 'Case Study';
        this.previousUrl = 'case-study';
      } else if (this.$route.params.category == 'mobile') {
        category = 'Mobile App';
        this.title = 'Mobile Applications';
        this.description =
          'Our team has decades of experience in custom mobile app development that spans various industries from retail to healthcare and energy. RunProf’s proactive team can dive into your business ecosystem, explore the market, and understand your needs, requirements, and capabilities';
        this.description2 = '';
        this.nextTitle = 'System';
        this.nextUrl = 'system';
        this.previousTitle = 'Websites';
        this.previousUrl = 'websites';
      } else if (this.$route.params.category == 'system') {
        category = 'System';
        this.title = 'Full System';
        this.description =
          'For many clients throughout the world, we have designed and created complete systems, including websites, ERP Systems and mobile applications with aspect ratio to help them improve their business and one of the main elements for their great achievements.';
        this.description2 = '';
        this.nextTitle = 'Branding';
        this.nextUrl = 'branding';
        this.previousTitle = 'Mobile Applications';
        this.previousUrl = 'mobile';
      } else if (
        this.$route.params.category == 'branding'
      ) {
        category = 'Branding';
        this.title = 'Branding';
        this.description =
          'When it comes to branding, everything needs to be clear to create or develop an identity for each brand And that’s why we are always sharpening our tools to deliver the best image could any brand have.';
        this.description2 = '';
        this.nextTitle = 'UI/UX';
        this.nextUrl = 'ui-ux';
        this.previousTitle = 'System';
        this.previousUrl = 'system';
      } else if (this.$route.params.category == 'ui-ux') {
        category = 'UI / UX';
        this.title = 'UI / UX';
        this.description =
          'An impressive UI goes beyond capturing the user’s attention, creating outstanding UX, and building the business brand. Hence, every business should focus on creating immersive UI designs that drive improved UX. At RunProf, our offshore UI/UX Design and development team comprehends user-centered designs’ value and understands their impact on your user experience. We ensure that users’ perspectives form the core of the development process and deliver enhanced usability in each of the software we create';
        this.description2 = '';
        this.nextTitle = 'Digital Marketing';
        this.nextUrl = 'digital-marketing';
        this.previousTitle = 'Branding';
        this.previousUrl = 'branding';
      } else if (
        this.$route.params.category == 'digital-marketing'
      ) {
        category = 'Digital Marketing';
        this.title = 'Digital Marketing';
        this.description =
          'We take pride in providing engaging digital marketing services. Many of our clients benefit from our award-winning solutions and experiences, which enable them, interact and engage with their customers in the most effective way imaginable';
        this.nextTitle = 'Case Study';
        this.nextUrl = 'case-study';
        this.previousTitle = 'UI / UX';
        this.previousUrl = 'ui-ux';
      } else if (
        this.$route.params.category == 'digital-marketing'
      ) {
        category = 'Digital Marketing';
        this.title = 'Digital Marketing';
        this.description =
          'We take pride in providing engaging digital marketing services. Many of our clients benefit from our award-winning solutions and experiences, which enable them, interact and engage with their customers in the most effective way imaginable.';
        this.description2 = '';
        this.nextTitle = 'Case Study';
        this.nextUrl = 'case-study';
        this.previousTitle = 'UI / UX';
        this.previousUrl = 'ui-ux';
      }
      return category;
    },
  },
};
